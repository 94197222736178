export function createIcon(fillColour, strokeColour) {
    const size = 25;
    const incidentsIcon = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // get rendering context for the map canvas when layer is added to the map
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
        },

        // called once before every frame where the icon will be used
        render: function () {
            const context = this.context;
            context.fillRect(
                this.width / 4,
                this.width / 4,
                this.width / 2,
                this.height / 2
            );
            context.strokeRect(
                this.width / 4,
                this.width / 4,
                this.width / 2,
                this.height / 2
            );
            context.fillStyle = fillColour;
            context.strokeStyle = strokeColour;
            context.lineWidth = 1;
            context.fill();
            context.stroke();

            // update this image's data with data from the canvas
            this.data = context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;

            // return `true` to let the map know that the image was updated
            return true;
        },
    };
    return incidentsIcon;
}
