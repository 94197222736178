import React, { ReactElement } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Typography, Grid } from '@material-ui/core';

import { TMapCardProperties } from './map-card.types';
import { StyledCard, StyledCardContent } from './map-card.styled';

export function MapCard({
    title,
    thumbnail,
    description,
    path,
}: TMapCardProperties): ReactElement {
    return (
        <StyledCard>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} lg={3}>
                    <Img fixed={thumbnail.childImageSharp.fixed} alt={title} />
                </Grid>
                <Grid item xs={12} sm={7} lg={9}>
                    <StyledCardContent>
                        <Typography component="h5" variant="h5">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {ReactHtmlParser(
                                description.replace(
                                    'INSERT YEAR',
                                    `${new Date().getFullYear()}`
                                )
                            )}
                        </Typography>
                        <Typography>
                            <Link to={`/${path}`}>See more</Link>
                        </Typography>
                    </StyledCardContent>
                </Grid>
            </Grid>
        </StyledCard>
    );
}
