import React, { Fragment } from 'react';
import { Container, Typography } from '@material-ui/core';

import { THeader } from '@components';
import { StyledHeader, StyledLogo } from './header.styled';

export function Header({ title, funderLogos, maxWidth }: THeader) {
    const content = (
        <Fragment>
            <Typography variant="h5" component="h1">
                {title}
            </Typography>
            <StyledLogo>
                {funderLogos?.map(logo => (
                    <a key={logo.url} href={logo.url}>
                        <img src={logo.logo} alt={logo.description} />
                    </a>
                ))}
            </StyledLogo>
        </Fragment>
    );

    if (maxWidth) {
        return (
            <StyledHeader>
                <Container maxWidth={maxWidth}>{content}</Container>
            </StyledHeader>
        );
    }

    return <StyledHeader>{content}</StyledHeader>;
}
