import styled from 'styled-components';

export const StyledMapContainer = styled.div`
    width: 100%;
    height: 100%;

    & :focus {
        outline: 0;
    }
`;

export const StyledMapProgress = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 50px;
    height: 50px;
    margin: auto;

    & :before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        content: '';
    }
`;
