import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const StyledReport = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledIncidents = styled.div`
    margin: ${({ theme }) => theme.spacing(1, 0, 2)};
`;

export const StyledEmphasis = styled.span`
    font-weight: bold;
    font-size: xx-large;
`;

export const StyledCategory = styled.div`
    height: 100%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.12);

    img {
        display: block;
        width: 50px;
        margin: auto;
    }
`;

export const StyledIndividualsGrid = styled(Grid)`
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTable = styled.div`
    padding-top: ${({ theme }) => theme.spacing(2)}px;
`;
