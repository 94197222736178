import styled from 'styled-components';

import { TStyledLegend, TStyledLegendItem } from './legend.types';

export const StyledLegend = styled.div<TStyledLegend>`
    position: absolute;
    bottom: ${({ legendType }) => (legendType === 'count' ? 100 : 15)}px;
    z-index: 1;
    margin-bottom: ${({ theme, legendType }) =>
        legendType === 'count' ? theme.spacing(2) : 0}px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    font-size: 14px;
    background-color: ${({ theme }) => theme.palette.colors.white};
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const StyledLegendItem = styled.span<TStyledLegendItem>`
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ theme, color }) => theme.palette.colors[color]};
    border: 1px solid ${({ theme }) => theme.palette.colors.grey200};
`;
