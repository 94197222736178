import React, { Fragment, ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';

import { TableReport } from '@components';
import { mozambique } from '@constants';
import { getAlphabeticallySortedData } from '@helpers';
import {
    TMozambiqueReport,
    TMozambiqueReportData,
} from './mozambique-report.types';
import {
    StyledIncidents,
    StyledEmphasis,
    StyledReport,
    StyledTable,
    StyledCategory,
    StyledIndividualsGrid,
} from './mozambique-report.styled';

function renderIndividualEvents(
    filterCategory,
    reportData: TMozambiqueReportData,
    category: string
): ReactElement {
    return (
        <Grid item xs={6}>
            <StyledCategory>
                <img
                    src={filterCategory[category]}
                    width="60px"
                    height="auto"
                    alt={mozambique.reportLabels[category]}
                />
                <Typography variant="h5">{reportData[category]}</Typography>
                <Typography variant="caption" display="block">
                    {mozambique.reportLabels[category]}
                </Typography>
            </StyledCategory>
        </Grid>
    );
}

export function MozambiqueReport({ filter, reportData }: TMozambiqueReport) {
    const filterCategory = mozambique.filters.categoryOptions.find(
        option => option.value === filter.category
    );

    return (
        <StyledReport>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img
                        src={filterCategory.imgActive}
                        width="60px"
                        height="auto"
                        style={{ display: 'block' }}
                        alt={'Logo'}
                    />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h6">{filterCategory.label}</Typography>
                    <Typography variant="subtitle2">
                        {format(new Date(filter.fromDate), 'dd/MM/yyyy')} -{' '}
                        {format(new Date(filter.toDate), 'dd/MM/yyyy')}
                    </Typography>
                </Grid>
            </Grid>

            <StyledIncidents>
                <Typography>
                    <StyledEmphasis>
                        {reportData?.totalIncidents}
                    </StyledEmphasis>{' '}
                    {filterCategory.reportText}
                </Typography>
            </StyledIncidents>

            {filter.category === mozambique.filters.categorys.individuals && (
                <Fragment>
                    <StyledIndividualsGrid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        {[
                            'aidWorkersInjured',
                            'aidWorkersKilledKidnapped',
                            'healthWorkersKilledKidnapped',
                            'educatorsKilledKidnapped',
                        ].map(category =>
                            renderIndividualEvents(
                                filterCategory,
                                reportData,
                                category
                            )
                        )}
                    </StyledIndividualsGrid>

                    <Typography variant="body1">
                        {mozambique.reportLabels.individualsDescription}
                    </Typography>
                </Fragment>
            )}

            {filter.category === mozambique.filters.categorys.crime &&
                reportData?.crime && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(reportData.crime)}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category === mozambique.filters.categorys.education &&
                reportData?.education && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.education
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category ===
                mozambique.filters.categorys.foodSecurityProgramme &&
                reportData?.foodSecurity && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.foodSecurity
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category === mozambique.filters.categorys.healthProgramme &&
                reportData?.health && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.health
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category === mozambique.filters.categorys.nearMiss &&
                reportData?.nearMiss && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.nearMiss
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category ===
                mozambique.filters.categorys.operationalSpace &&
                reportData?.operationalSpace && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.operationalSpace
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category ===
                mozambique.filters.categorys.roadSafetyAccident &&
                reportData?.roadSafetyAccident && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.categories}
                            data={getAlphabeticallySortedData(
                                reportData.roadSafetyAccident
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category !==
                mozambique.filters.categorys.roadSafetyAccident &&
                reportData?.perpetrators && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.perpetratorCategory}
                            data={getAlphabeticallySortedData(
                                reportData.perpetrators
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}

            {filter.category !==
                mozambique.filters.categorys.roadSafetyAccident &&
                reportData?.weaponUse && (
                    <StyledTable>
                        <TableReport
                            header={mozambique.reportLabels.weaponCategory}
                            data={getAlphabeticallySortedData(
                                reportData.weaponUse
                            )}
                            colour={filterCategory.colour}
                        />
                    </StyledTable>
                )}
        </StyledReport>
    );
}
