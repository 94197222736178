import React, {
    useRef,
    useEffect,
    useState,
    useReducer,
    Fragment,
    ReactElement,
} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import mapboxgl from '!mapbox-gl';

import { Map as TMapboxMap } from '!mapbox-gl';

import { LAYER_SOURCE_NAMES } from '@constants';

import { TCovidMap, TInitialState, TAction } from './covid-map.types';
import {
    initMap,
    aggregateCountryIncidentData,
    updateIncidentLayers,
    setCountryStyles,
    updateCountriesLayer,
    getFilterColumn,
} from './covid-map.logic';

import { StyledMapProgress, StyledMapContainer } from './covid-map.styled';
import { filterCovidIncidents } from '@helpers';

const INITIAL_STATE: TInitialState = {
    countryCode: '',
    isMapLoading: true,
    filteredIncidents: [],
};

function reducer(state: TInitialState, action: TAction) {
    switch (action.type) {
        case 'updateMapLoadingState':
            return { ...state, isMapLoading: action.value };
        case 'setSelectedCountry':
            return { ...state, countryCode: action.value };
        case 'setFilteredIncidents':
            return { ...state, filteredIncidents: action.value };
        case 'clearSelectedCountry':
            return { ...state, countryCode: '' };
        default:
            return INITIAL_STATE;
    }
}

export function CovidMap({
    filter,
    incidents,
    selectedCountry,
    setSelectedCountry,
    clearSelectedCountry,
    setGlobalStatistics,
    dataSourceAttribution,
}: TCovidMap): ReactElement {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const mapReference = useRef<HTMLDivElement>(null);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const [map, setMap] = useState<TMapboxMap>();

    function handleClickEventOnCountry(countryCode: string): void {
        dispatch({ type: 'setSelectedCountry', value: countryCode });
    }

    function handleClickEventOutsideCountry(): void {
        dispatch({ type: 'clearSelectedCountry' });
    }

    useEffect(() => {
        if (!selectedCountry) dispatch({ type: 'clearSelectedCountry' });
    }, [selectedCountry]);

    useEffect(() => {
        if (!map?.isStyleLoaded()) return;

        const { filteredIncidents, countryCode } = state;

        if (!countryCode) clearSelectedCountry();
        else
            setSelectedCountry(
                aggregateCountryIncidentData(filteredIncidents, countryCode)
            );

        setCountryStyles(map, filter.category, countryCode);
    }, [state]);

    useEffect(() => {
        if (!incidents || !map?.isStyleLoaded()) {
            return;
        }
        const { countryCode } = state;

        const filterColumn = getFilterColumn(filter.category);

        const filteredIncidents = filterCovidIncidents(
            incidents,
            filter,
            filterColumn
        );

        dispatch({ type: 'setFilteredIncidents', value: filteredIncidents });

        if (!selectedCountry) setCountryStyles(map, filter.category);
        else dispatch({ type: 'setSelectedCountry', value: countryCode });
    }, [incidents, filter]);

    useEffect(() => {
        if (!incidents || !map.getSource(LAYER_SOURCE_NAMES.COUNTRIES)) {
            return;
        }
        const { filteredIncidents } = state;

        updateCountriesLayer(map, filteredIncidents);

        setGlobalStatistics(filteredIncidents);

        updateIncidentLayers(map, filteredIncidents);
    }, [state.filteredIncidents]);

    useEffect(() => {
        if (mapReference.current && incidents) {
            const filterColumn = getFilterColumn(filter.category);
            const filteredIncidents = filterCovidIncidents(
                incidents,
                filter,
                filterColumn
            );

            const mapboxMapObject = initMap(
                mapReference.current,
                filteredIncidents,
                filter.category,
                handleClickEventOnCountry,
                handleClickEventOutsideCountry,
                dataSourceAttribution
            );

            dispatch({
                type: 'setFilteredIncidents',
                value: filteredIncidents,
            });
            setGlobalStatistics(filteredIncidents);
            dispatch({ type: 'updateMapLoadingState', value: false });
            setMap(mapboxMapObject);
        }
    }, [incidents]);

    return (
        <Fragment>
            {state.isMapLoading && (
                <StyledMapProgress>
                    <CircularProgress />
                </StyledMapProgress>
            )}
            <StyledMapContainer id="covid-map" ref={mapReference} />
        </Fragment>
    );
}
