import styled from 'styled-components';

export const StyledHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: ${({ theme }) => theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.colors.white};
    background-color: ${({ theme }) => theme.palette.colors.teal};
`;

export const StyledLogo = styled.div`
    img {
        height: 40px;
        margin: ${({ theme }) => theme.spacing(0, 1)};
    }
`;
