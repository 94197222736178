import styled from 'styled-components';

import { FormControl, Typography } from '@material-ui/core';

export const StyledFormControl = styled(FormControl)`
    margin: ${({ theme }) => theme.spacing(2, 0)};
    padding: ${({ theme }) => theme.spacing(2, 0)};
    border: none;
`;

export const StyledImg = styled.img`
    width: 25px;
    height: 25px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledIncidentCategory = styled(Typography)`
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`;

export const StyledLabel = styled.div`
    display: flex;
    align-items: center;
`;
