import React from 'react';
import { ListItem, List, ListItemText } from '@material-ui/core';

import { REPORT_LABELS } from '@constants';
import { StyledStatistic } from './incident-effects-report.styled';
import { TIncidentEffectsReport } from './incident-effects-report.types';

export function IncidentEffectsReport({
    healthFacilitiesDamagedDestroyed,
    healthWorkersInjured,
    healthWorkersKidnapped,
    healthWorkersKilled,
}: TIncidentEffectsReport) {
    return (
        <List disablePadding>
            <ListItem dense disableGutters>
                <ListItemText>
                    <StyledStatistic>
                        {healthFacilitiesDamagedDestroyed}
                    </StyledStatistic>
                    {REPORT_LABELS.HEALTH_FACILITIES_DAMAGED_DESTROYED}
                </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
                <ListItemText>
                    <StyledStatistic>{healthWorkersKilled}</StyledStatistic>
                    {REPORT_LABELS.HEALTH_WORKERS_KILLED}
                </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
                <ListItemText>
                    <StyledStatistic>{healthWorkersKidnapped}</StyledStatistic>
                    {REPORT_LABELS.HEALTH_WORKERS_KIDNAPPED}
                </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
                <ListItemText>
                    <StyledStatistic>{healthWorkersInjured}</StyledStatistic>
                    {REPORT_LABELS.HEALTH_WORKERS_INJURED}
                </ListItemText>
            </ListItem>
        </List>
    );
}
