import mapboxgl, { Map } from '!mapbox-gl';
import { TCovidSecurityIncident, THealthSecurityIncident } from '@types';

import { TSelectedCountry } from '@components';
import {
    STEP_STYLE,
    MAPBOX_BASEMAP_STYLE,
    INCIDENTS_ICON,
    DATA_VALUES,
    LAYER_NAMES,
    LAYER_SOURCE_NAMES,
    NO_DESCRIPTION_AVAILABLE,
    COUNTRY_COUNT_STYLE,
} from '@constants';
import {
    aggregateHealthImpactCaused,
    aggregateIncidentsByPerpetrators,
    createIcon,
} from '@helpers';
import { TIncidentsGeoJson, TCountry } from './covid-map.types';

import { defaultTheme } from '../../styles';

import countries from '../../data/ne_10m_admin_0_countries_usa.json';

function addIncidentsPopupEvents(map: Map) {
    return event => {
        const feature = event.features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const description =
            feature.properties.incidentDescription &&
            feature.properties.displayDescriptionStatus === 'Yes'
                ? `${feature.properties.incidentDescription} (ID: ${feature.properties.incidentNumber})`
                : `${NO_DESCRIPTION_AVAILABLE} (ID: ${feature.properties.incidentNumber})`;

        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
    };
}

function getCountsForColumn(
    incidents: TCovidSecurityIncident[],
    countryCode: string
) {
    return incidents.reduce((accumulator, currentValue) => {
        accumulator[currentValue[countryCode]] =
            (accumulator[currentValue[countryCode]] || 0) + 1;
        return accumulator;
    }, {});
}

export function aggregateCountryIncidentData(
    incidents: TCovidSecurityIncident[] | THealthSecurityIncident[],
    countryCode: string
): TSelectedCountry {
    const country = countries.features.find(
        country => country.properties.ISO_A3_EH === countryCode
    );

    const incidentsForCountry = incidents.filter(
        incident => incident.isoCode === countryCode
    );

    const aggregateHealthImpacts = aggregateHealthImpactCaused(
        incidentsForCountry
    );

    const countryData = {
        country: country.properties.NAME_EN,
        totalIncidents: incidentsForCountry.length,
        healthFacilitiesDamagedDestroyed:
            aggregateHealthImpacts('totalHealthFacilitiesDamaged') +
            aggregateHealthImpacts('totalHealthFacilitiesDestroyed'),
        healthWorkersKilled: aggregateHealthImpacts('totalHealthWorkerKilled'),
        healthWorkersKidnapped: aggregateHealthImpacts(
            'totalHealthWorkerKidnapped'
        ),
        healthWorkersInjured:
            aggregateHealthImpacts('totalHealthWorkerInjured') +
            aggregateHealthImpacts('totalHealthWorkerAssaulted'),
        perpetrators: aggregateIncidentsByPerpetrators('perpetratorMap')(
            incidentsForCountry
        ),
        healthMeasureType: aggregateIncidentsByPerpetrators('healthMeasureMap')(
            incidentsForCountry
        ),
        weaponUse: aggregateIncidentsByPerpetrators('weaponUseMap')(
            incidentsForCountry
        ),
    };

    return countryData;
}

export function initMap(
    mapContainer: HTMLDivElement,
    incidents: TCovidSecurityIncident[],
    filterCategory: string,
    handleClickEventOnCountry: (countyCode: string) => void,
    handleClickEventOutsideCountry: () => void
    // dataSourceAttribution
) {
    const map = new mapboxgl.Map({
        container: mapContainer,
        style: MAPBOX_BASEMAP_STYLE,
        center: [0, 0],
        zoom: 1.5,
        attributionControl: false,
        maxBounds: [
            [-180, -85],
            [180, 85],
        ],
    });

    map.on('load', () => {
        addIncidentsLayersToMap(incidents, map);
        addCountriesLayerToMap(map, incidents, filterCategory);
    });

    // Add zoom and rotation controls to the map.
    map.addControl(
        new mapboxgl.NavigationControl({
            showCompass: false,
        }),
        'bottom-left'
    );

    // Prevents country click interaction in case user clicked an event
    let clickedIncident = false;
    map.on('click', LAYER_NAMES.POINTS, event => {
        addIncidentsPopupEvents(map)(event);
        clickedIncident = true;
    });

    map.on('click', LAYER_NAMES.COUNTRIES, function (event) {
        if (clickedIncident) clickedIncident = false;
        else
            handleClickEventOnCountry(
                event?.features[0]?.properties?.ISO_A3_EH
            );
    });

    map.on('click', event => {
        // Check if the click event's target is within your layer
        const features = map.queryRenderedFeatures(event.point);
        const isInsideLayer = features.some(function (feature) {
            return feature.layer.id === LAYER_NAMES.COUNTRIES;
        });

        // If the click event is not inside your layer, do something
        if (!isInsideLayer) {
            // Your code here for handling click outside the layer
            handleClickEventOutsideCountry();
        }
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', LAYER_NAMES.COUNTRIES, function () {
        map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a grab when it leaves.
    map.on('mouseleave', LAYER_NAMES.COUNTRIES, function () {
        map.getCanvas().style.cursor = 'grab';
    });

    return map;
}

export function assignIncidentCountToCountry(
    incidents: TCovidSecurityIncident[]
): void {
    const incidentsCountPerCountry = getCountsForColumn(incidents, 'isoCode');

    for (const country: TCountry of countries.features) {
        country.properties.incidentsCount =
            incidentsCountPerCountry[country.properties.ISO_A3_EH] ?? 0;
    }
}
function isArrayofTwoNumbers(variable) {
    return (
        Array.isArray(variable) &&
        variable.length === 2 &&
        variable.every(number => typeof number === 'number')
    );
}

function setBoundsToCountry(map: Map, selectedCountryCode: string): void {
    const features = map.querySourceFeatures(LAYER_SOURCE_NAMES.COUNTRIES, {
        sourceLayer: LAYER_NAMES.COUNTRIES,
        filter: [
            'match',
            ['get', 'ISO_A3_EH'],
            selectedCountryCode,
            true,
            false,
        ],
    });

    const bounds = new mapboxgl.LngLatBounds();
    for (const feature of features) {
        const coordinateSets = feature.geometry.coordinates[0];
        for (const coordinateSet of coordinateSets) {
            if (isArrayofTwoNumbers(coordinateSet))
                bounds.extend(coordinateSet);
            else {
                for (const subSet of coordinateSet) {
                    if (isArrayofTwoNumbers(subSet)) bounds.extend(subSet);
                }
            }
        }
    }

    map.fitBounds(bounds, {
        padding: 140, // Adjust the padding as needed
    });
}

export function setCountryStyles(
    map: Map,
    filterCategory: string,
    selectedCountryCode?: string
): void {
    const filterStyles = COUNTRY_COUNT_STYLE[filterCategory].flatMap(style => [
        style.VALUE,
        defaultTheme.palette.colors[style.COLOUR],
    ]);
    filterStyles.shift();
    if (selectedCountryCode && selectedCountryCode !== '') {
        map.setFilter(LAYER_NAMES.POINTS, [
            '==',
            ['get', 'countryCode'],
            selectedCountryCode,
        ]);

        map.setPaintProperty(LAYER_NAMES.COUNTRIES, 'fill-color', [
            'match',
            ['get', 'ISO_A3_EH'],
            selectedCountryCode,
            'rgba(0, 0, 0, 0)',
            'DarkGray',
        ]);

        setBoundsToCountry(map, selectedCountryCode);
    } else {
        map.setFilter(LAYER_NAMES.POINTS);

        map.setPaintProperty(LAYER_NAMES.COUNTRIES, 'fill-color', [
            ...STEP_STYLE,
            ...filterStyles,
        ]);
    }
}

function addCountriesLayerToMap(
    map: Map,
    incidents: TCovidSecurityIncident[],
    filterCategory: string
) {
    assignIncidentCountToCountry(incidents);
    // Load country dataset
    map.addSource(LAYER_SOURCE_NAMES.COUNTRIES, {
        type: 'geojson',
        data: countries,
    });

    map.addLayer(
        {
            id: LAYER_NAMES.COUNTRIES,
            source: LAYER_SOURCE_NAMES.COUNTRIES,
            type: 'fill',
            paint: {
                'fill-outline-color': 'white',
            },
        },
        getFirstSymbol(map)
    );

    setCountryStyles(map, filterCategory);
}

function getFirstSymbol(map: Map): string | undefined {
    const layers = map.getStyle().layers;
    // Find the index of the first symbol layer in the map style
    return layers?.find(layer => layer.type === 'symbol')?.id;
}

export function updateIncidentLayers(
    map: Map,
    incidents: TCovidSecurityIncident[]
) {
    map.getSource(LAYER_SOURCE_NAMES.POINTS).setData(
        createIncidentsGeoJson(incidents)
    );
}

export function updateCountriesLayer(
    map: Map,
    incidents: TCovidSecurityIncident[]
) {
    assignIncidentCountToCountry(incidents);
    map.getSource(LAYER_SOURCE_NAMES.COUNTRIES).setData(countries);
}

export function addIncidentsLayersToMap(
    incidents: TCovidSecurityIncident[],
    map: Map
): void {
    map.addImage(
        'incidentsIcon',
        createIcon(INCIDENTS_ICON.COLOUR, INCIDENTS_ICON.BORDER_COLOUR),
        { pixelRatio: 2 }
    );
    map.addImage(
        'incidentsIconNoDescription',
        createIcon(
            INCIDENTS_ICON.NO_DESCRIPTION_COLOUR,
            INCIDENTS_ICON.NO_DESCRIPTION_BORDER_COLOUR
        ),
        { pixelRatio: 2 }
    );

    map.addSource(LAYER_SOURCE_NAMES.POINTS, {
        type: 'geojson',
        data: createIncidentsGeoJson(incidents),
    });
    map.addLayer({
        id: LAYER_NAMES.POINTS,
        source: LAYER_SOURCE_NAMES.POINTS,
        type: 'symbol',
        layout: {
            'icon-image': 'incidentsIcon',
            'icon-allow-overlap': true,
        },
        filter: ['==', 'displayDescriptionStatus', 'Yes'],
    });
}

export function getFilterColumn(
    category: string
): { dataColumn: string; value: string } {
    switch (category) {
        case 'Covid':
            return {
                dataColumn: 'covidRelatedViolence',
                value: DATA_VALUES.COVID,
            };
        case 'Conflict':
            return {
                dataColumn: 'conflictViolence',
                value: DATA_VALUES.CONFLICT,
            };
        case 'Political':
            return {
                dataColumn: 'politicalRelatedViolence',
                value: DATA_VALUES.POLITICAL,
            };
        case 'Ebola':
            return {
                dataColumn: 'ebolaRelatedViolence',
                value: DATA_VALUES.EBOLA,
            };
        case 'Vaccination':
            return {
                dataColumn: 'vaccinationRelatedViolence',
                value: DATA_VALUES.VACCINATION,
            };
        default: {
            return {
                dataColumn: 'allEventsAffectingHealthcare',
                value: DATA_VALUES.HEALTH,
            };
        }
    }
}

function createIncidentsGeoJson(
    incidents: TCovidSecurityIncident[]
): TIncidentsGeoJson {
    return {
        type: 'FeatureCollection',
        features: incidents
            .map(incident => {
                if (incident.latitude && incident.longitude) {
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [
                                Number(incident.longitude.replace(/ /g, '')),
                                Number(incident.latitude.replace(/ /g, '')),
                            ],
                        },
                        properties: {
                            incidentNumber: incident.incidentNumber,
                            incidentDescription:
                                incident.editedIncidentDescription,
                            covidEvent: incident.covidRelatedViolence,
                            conflictEvent: incident.conflictViolence,
                            healthEvent: incident.allEventsAffectingHealthcare,
                            isoDate: incident.isoDate,
                            countryCode: incident.isoCode,
                            displayDescriptionStatus: incident.displayStatus,
                        },
                    };
                }
            })
            .filter(Boolean),
    };
}
