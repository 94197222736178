export const MAPBOX_BASEMAP_STYLE = 'mapbox://styles/mapbox/light-v9'; // Style of MapBox basemap. For options see here: https://www.mapbox.com/maps/

export const LAYER_NAMES = {
    POINTS: 'points',
    POINTS_NO_DESCRIPTION: 'pointsNoDescription',
    COUNTRIES: 'countries',
};

export const LAYER_SOURCE_NAMES = {
    POINTS: 'points',
    COUNTRIES: 'countries',
};

export const DATA_VALUES = {
    HEALTH: 'Health',
    COVID: 'COVIDEvent',
    CONFLICT: 'ConflictEvent',
    POLITICAL: 'Politically-related',
    EBOLA: 'Ebola-related',
    VACCINATION: 'Vaccination-related',
};

export const INCIDENTS_ICON = {
    SIZE: 25,
    COLOUR: '#ffdb58',
    BORDER_COLOUR: 'Gray',
    NO_DESCRIPTION_COLOUR: '#dcdcdc',
    NO_DESCRIPTION_BORDER_COLOUR: 'DarkSlateGray',
};

export const STEP_STYLE = ['step', ['get', 'incidentsCount']];

export const COUNTRY_COUNT_STYLE = {
    Health: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'blue100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'blue200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'blue300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'blue400',
            LABEL: '250 +',
        },
    ],
    Covid: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'green100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'green200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'green300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'green400',
            LABEL: '250 +',
        },
    ],
    Conflict: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'red100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'red200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'red300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'red400',
            LABEL: '250 +',
        },
    ],
    Political: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'darkorange100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'darkorange200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'darkorange300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'darkorange400',
            LABEL: '250 +',
        },
    ],
    Ebola: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'teal100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'teal200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'teal300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'teal400',
            LABEL: '250 +',
        },
    ],
    Vaccination: [
        {
            VALUE: 0,
            COLOUR: 'white',
            LABEL: '0',
        },
        {
            VALUE: 1,
            COLOUR: 'gold100',
            LABEL: '1 - 50',
        },
        {
            VALUE: 50,
            COLOUR: 'gold200',
            LABEL: '50 - 100',
        },
        {
            VALUE: 100,
            COLOUR: 'gold300',
            LABEL: '100 - 250',
        },
        {
            VALUE: 250,
            COLOUR: 'gold400',
            LABEL: '250 +',
        },
    ],
};
