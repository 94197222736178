import React, { useState, useEffect, ReactElement } from 'react';

import { StyledBarChart } from './bar-chart.styled';
import { TBarChart } from './bar-chart.types';

const calculatePercentage = (value, maxValue) => {
    if (!Number.isNaN(maxValue) && !Number.isNaN(value) && maxValue !== 0) {
        return Number(((value / maxValue) * 100).toFixed());
    }
    return 0;
};

export function BarChart({ value, maxValue, colour }: TBarChart): ReactElement {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const percentage = calculatePercentage(value, maxValue);
        setPercentage(percentage);
    }, [value, maxValue]);

    return (
        <StyledBarChart>
            <div
                className="barchart__bar"
                style={{
                    height: '100%',
                    width: `${Math.min(percentage, 100)}%`,
                    backgroundColor: `${colour}`,
                }}
            />
        </StyledBarChart>
    );
}
