export const LEGEND_LABEL = {
    INCIDENT: 'Incident',
    INCIDENT_WITH_DESCRIPTION: 'Incident (with description)',
    INCIDENT_NO_DESCRIPTION: 'Incident (no description)',
    INCIDENT_COUNT: 'Incident Count',
};

export const NO_DESCRIPTION_AVAILABLE = 'No description available';
export const NO_DATA = 'No data';
export const MOBILE_SCREEN_MESSAGE =
    'To make full use of the website, please use a larger screen.';

// Report
export const TOTAL_INCIDENTS = 'reported incidents globally';

export const REPORT_LABELS = {
    HEALTH_FACILITIES_DAMAGED_DESTROYED: 'Health facilities damaged',
    HEALTH_WORKERS_INJURED: 'Health workers injured',
    HEALTH_WORKERS_KIDNAPPED: 'Health workers kidnapped',
    HEALTH_WORKERS_KILLED: 'Health workers killed',
    PERPETRATOR_CATEGORY: 'Reported perpetrator',
    WEAPON_CATEGORY: 'Weapon category used',
    HEALTH_MEASURE_CATEGORY: 'COVID-19 related motive for violence',
};

export const PERPETRATOR_TYPE_LOOKUP = {
    'No Information': 'No information',
    'More than one perpetrator': 'Multiple',
    'Employee or former employee': 'Employee',
    'Protestors': 'Protestors',
    'Patient or their family member': 'Patient/family',
    'State military': 'State military',
    'Law enforcement': 'Police',
    'Opposition group': 'NSA',
    'Paramilitary and vigilante groups': 'Vigilante',
    'PoliticalParty': 'Political party',
    'Criminal': 'Criminal',
    'Civilian': 'Civilian',
    'Other': 'Other'
};

export const WEAPON_USE_TYPE_LOOKUP = {
    'No information': 'No information',
    'Aerial bombing, missiles and shelling': 'Explosive weapon',
    'Arson': 'Arson',
    'Blunt, dagger, knife and machetes': 'Knife',
    'Chemical and hot liquids': 'Liquid',
    'Firearms': 'Firearms',
    'IEDs': 'IEDs',
    'Missiles and shelling': 'Explosive weapon',
    'Stones': 'Stones',
    'Tasers, live and rubber bullets, tear gas and water cannons':
        'Police weapon',
};

export const HEALTH_MEASURE_TYPE_LOOKUP = {
    'No information': 'No information',
    'Objection to hospitals or buildings being used to treat COVID patients':
        'Objection to hospitals or buildings being used to treat COVID patients',
    'Objection to medical health measures':
        'Objection to medical health measures',
    'Objection to public health measures':
        'Objection to public health measures',
    'Speaking out': 'Speaking out against health management',
    'Way to work': 'Way to and from work',
};
