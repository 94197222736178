import React, { useState } from 'react';

import { MOBILE_SCREEN_MESSAGE } from '@constants';
import { StyledClose, StyledMessage } from './mobile-screen-message.styled';

export function MobileScreenMessage() {
    const [showMobileScreenMessage, setShowMobileScreenMessage] = useState(
        true
    );

    return (
        <div>
            {showMobileScreenMessage && (
                <StyledMessage>
                    <span>{MOBILE_SCREEN_MESSAGE}</span>
                    <StyledClose
                        onClick={() => setShowMobileScreenMessage(false)}
                    >
                        X
                    </StyledClose>
                </StyledMessage>
            )}
        </div>
    );
}
