import styled from 'styled-components';

export const StyledFundersContainer = styled.div`
    padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledFunders = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
`;

export const StyledImg = styled.img`
    width: 100%;
`;

export const StyledDescriptionContainer = styled.div`
    padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const StyledSidebar = styled.div`
    height: calc(100vh - 100px);
    padding: ${({ theme }) => theme.spacing(2)}px;
    overflow-y: auto;
    border-right: 1px solid ${({ theme }) => theme.palette.colors.grey300};
`;
