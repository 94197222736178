import styled from 'styled-components';

export const StyledMessage = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 2;
    display: block;
    width: 100%;
    padding: 10px;
    color: white;
    text-align: center;
    background: #0b4f67;

    @media only screen and (min-width: 992px) {
        display: none;
    }
`;

export const StyledClose = styled.span`
    padding-left: 10px;
    font-weight: bold;
    cursor: pointer;
`;
