import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const StyledIncidentCount = styled.div`
    position: absolute;
    top: 100px;
    max-width: 250px;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
`;

export const StyledTypography = styled(Typography)`
    flex: 1;
`;

export const StyledIncidentRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(1)}px;

    &:first-of-type {
        margin-top: 0;
    }
    > * {
        width: 100%;
    }
`;

export const StyledIcon = styled.img`
    width: 50px;
    height: auto;
`;

export const StyledIncidentEmphasis = styled.span`
    flex: 1;
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: 16px;
`;
