/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { TSeo } from './seo.types';

export function Seo({ lang = 'en', title, description }: TSeo): ReactElement {
    const {
        site: { siteMetadata },
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        url
                        image
                        twitterUsername
                    }
                }
            }
        `
    );

    const metaDescription = description || siteMetadata.description;
    const defaultTitle = siteMetadata?.title;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
        >
            {siteMetadata.url && (
                <meta property="og:url" content={siteMetadata.url} />
            )}
            {title && <meta property="og:title" content={title} />}
            <meta property="og:description" content={metaDescription} />
            {siteMetadata.image && (
                <meta property="og:image" content={siteMetadata.image} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {siteMetadata.twitterUsername && (
                <meta
                    name="twitter:creator"
                    content={siteMetadata.twitterUsername}
                />
            )}
            {title && <meta name="twitter:title" content={title} />}
            {description && (
                <meta name="twitter:description" content={description} />
            )}
            {siteMetadata.image && (
                <meta name="twitter:image" content={siteMetadata.image} />
            )}
        </Helmet>
    );
}
