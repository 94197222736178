import { isAfter, isBefore, endOfDay, startOfDay, parse } from 'date-fns';

import { TFilter } from '@components';
import {
    TCovidSecurityIncident,
    THealthSecurityIncident,
    TMozambiqueSecurityIncident,
} from '@types';

export function filterCovidIncidents(
    incidents: TCovidSecurityIncident[] | THealthSecurityIncident[],
    filter: TFilter,
    filterColumn: { dataColumn: string; value: string }
): TCovidSecurityIncident[] | THealthSecurityIncident[] {
    return incidents.filter(
        incident =>
            incident[
                filterColumn.dataColumn as keyof THealthSecurityIncident
            ] === filterColumn.value &&
            isAfter(
                endOfDay(parse(incident.isoDate, 'dd/MM/yyyy', new Date())),
                startOfDay(new Date(filter.fromDate))
            ) &&
            isBefore(
                startOfDay(parse(incident.isoDate, 'dd/MM/yyyy', new Date())),
                endOfDay(new Date(filter.toDate))
            )
    );
}

export function filterMozambiqueIncidents(
    incidents: TMozambiqueSecurityIncident[],
    filter: TFilter,
    filterColumn: string
): TMozambiqueSecurityIncident[] {
    return incidents.filter(
        incident =>
            incident[filterColumn as keyof TMozambiqueSecurityIncident] !==
                '' &&
            isAfter(
                endOfDay(parse(incident.isoDate, 'dd/MM/yyyy', new Date())),
                startOfDay(new Date(filter.fromDate))
            ) &&
            isBefore(
                startOfDay(parse(incident.isoDate, 'dd/MM/yyyy', new Date())),
                endOfDay(new Date(filter.toDate))
            )
    );
}
