import React from 'react';
import Typography from '@material-ui/core/Typography';

import { COUNTRY_COUNT_STYLE, LEGEND_LABEL } from '@constants';
import { TLegend } from './legend.types';
import { StyledLegend, StyledLegendItem } from './legend.styled';

export function Legend({ filter }: TLegend) {
    return (
        <div>
            <StyledLegend legendType="count">
                <Typography variant="subtitle2">
                    {LEGEND_LABEL.INCIDENT_COUNT}
                </Typography>
                {COUNTRY_COUNT_STYLE[filter.category]?.map((item, index) => (
                    <div key={index}>
                        <StyledLegendItem color={item.COLOUR} />
                        {item.LABEL}
                    </div>
                ))}
            </StyledLegend>
            <StyledLegend legendType="incident">
                <Typography variant="subtitle2">
                    {LEGEND_LABEL.INCIDENT}
                </Typography>
                <div>
                    <StyledLegendItem color="yellow" legendType="incident" />
                    {LEGEND_LABEL.INCIDENT_WITH_DESCRIPTION}
                </div>
                <div>
                    <StyledLegendItem color="grey100" legendType="incident" />
                    {LEGEND_LABEL.INCIDENT_NO_DESCRIPTION}
                </div>
            </StyledLegend>
        </div>
    );
}
