import edaLogo from '../static/logos/eda_logo.jpg';
import h2hLogo from '../static/logos/h2h_full_logo.png';
import ukAidLogo from '../static/logos/uk_aid_logo.png';
import gffoLogo from '../static/logos/gffo_logo.png';
import riahLogo from '../static/logos/riah_logo.png';
import mapactionLogo from '../static/logos/mapaction_logo.png';
import InsecurityInsightLogo from '../static/logos/insecurity_insight_logo_blue.png';
import shicLogo from '../static/logos/safeguarding_health_in_conflict.png';
import jhLogo from '../static/logos/JHSPHLogo.jpg';
import dornsifeLogo from '../static/logos/Dornsife.jpg';
import icnLogo from '../static/logos/ICNlogo.png';
import phrLogo from '../static/logos/phr_logo.png';
import intrahealthLogo from '../static/logos/Intrahealth.png';
import { subWeeks } from 'date-fns';

import IMG_HEALTH_ACTIVE from '../static/covid/health_active.png';
import IMG_HEALTH_INACTIVE from '../static/covid/health_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_ALL from '../static/covid/health_facilities_damaged_destroyed_all.png';
import IMG_HEALTH_WORKERS_INJURED_ALL from '../static/covid/health_workers_injured_all.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_ALL from '../static/covid/health_workers_kidnapped_all.png';
import IMG_HEALTH_WORKERS_KILLED_ALL from '../static/covid/health_workers_killed_all.png';

import IMG_COVID_ACTIVE from '../static/covid/covid_active.png';
import IMG_COVID_INACTIVE from '../static/covid/covid_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_COVID from '../static/covid/health_facilities_damaged_destroyed_covid.png';
import IMG_HEALTH_WORKERS_INJURED_COVID from '../static/covid/health_workers_injured_covid.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_COVID from '../static/covid/health_workers_kidnapped_covid.png';
import IMG_HEALTH_WORKERS_KILLED_COVID from '../static/covid/health_workers_killed_covid.png';

import IMG_CONFLICT_ACTIVE from '../static/covid/conflict_active.png';
import IMG_CONFLICT_INACTIVE from '../static/covid/conflict_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_CONFLICT from '../static/covid/health_facilities_damaged_destroyed_conflict.png';
import IMG_HEALTH_WORKERS_INJURED_CONFLICT from '../static/covid/health_workers_injured_conflict.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_CONFLICT from '../static/covid/health_workers_kidnapped_conflict.png';
import IMG_HEALTH_WORKERS_KILLED_CONFLICT from '../static/covid/health_workers_killed_conflict.png';

export const covid = {
    funders: [
        {
            logo: edaLogo,
            description: 'EDA Logo',
            view: 'sidebar',
        },
        {
            logo: h2hLogo,
            description: 'H2H Network Logo',
            view: 'sidebar',
        },
        {
            logo: ukAidLogo,
            description: 'UK Aid Logo',
            view: 'sidebar',
        },
        {
            logo: gffoLogo,
            description: 'GFFO Logo',
            view: 'sidebar',
        },
        {
            logo: riahLogo,
            description: 'Riah Logo',
            view: 'sidebar',
        },
        {
            logo: InsecurityInsightLogo,
            description: 'Insecurity Insight Logo',
            url: 'http://insecurityinsight.org/',
            view: 'header',
        },
        {
            logo: shicLogo,
            description: 'Safeguarding Health in Conflict Logo',
            url: 'https://www.safeguardinghealth.org/',
            view: 'header',
        },
        {
            logo: mapactionLogo,
            description: 'MapAction Logo',
            url: 'https://mapaction.org/',
            view: 'header',
        },
        {
            logo: jhLogo,
            description: 'Johns Hopkins Logo',
            url: 'https://www.jhsph.edu/',
            view: 'header',
        },
        {
            logo: dornsifeLogo,
            description: 'Dornsife Logo',
            url: 'https://drexel.edu/dornsife/',
            view: 'header',
        },
        {
            logo: icnLogo,
            description: 'ICN Logo',
            url: 'https://www.icn.ch/',
            view: 'header',
        },
        {
            logo: phrLogo,
            description: 'Physicians for Human Rights Logo',
            url: 'https://phr.org/',
            view: 'header',
        },
        {
            logo: intrahealthLogo,
            description: 'Intrahealth Logo',
            url: 'https://www.intrahealth.org/',
            view: 'header',
        },
    ],
    filters: {
        categorys: {
            health: 'Health',
            covid: 'Covid',
            conflict: 'Conflict',
        },
        categoryOptions: [
            {
                value: 'Health',
                label: 'Affecting health care',
                labelGlobalStats: 'Health care',
                colour: '#4281C2',
                reportText:
                    'reported incidents of violence or threat of violence against health care. These incidents had the following effects:',
                imgActive: IMG_HEALTH_ACTIVE,
                imgInactive: IMG_HEALTH_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_ALL,
                injured: IMG_HEALTH_WORKERS_INJURED_ALL,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_ALL,
                killed: IMG_HEALTH_WORKERS_KILLED_ALL,
            },
            {
                value: 'Covid',
                label: 'COVID-19 related',
                labelGlobalStats: 'COVID-19',
                colour: '#548235',
                reportText:
                    'reported incidents of violence or threats of violence against health care directly related to COVID-19. These incidents had the following effects:',
                imgActive: IMG_COVID_ACTIVE,
                imgInactive: IMG_COVID_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_COVID,
                injured: IMG_HEALTH_WORKERS_INJURED_COVID,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_COVID,
                killed: IMG_HEALTH_WORKERS_KILLED_COVID,
            },
            {
                value: 'Conflict',
                label: 'Conflict related',
                labelGlobalStats: 'Conflict',
                colour: '#920000',
                reportText:
                    'reported incidents of conflict violence that affected health care. These incidents had the following effects:',
                imgActive: IMG_CONFLICT_ACTIVE,
                imgInactive: IMG_CONFLICT_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_CONFLICT,
                injured: IMG_HEALTH_WORKERS_INJURED_CONFLICT,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_CONFLICT,
                killed: IMG_HEALTH_WORKERS_KILLED_CONFLICT,
            },
        ],
        dates: {
            from: new Date('2020-01-01'),
            to: subWeeks(new Date(), 6), // This is 6 weeks before today
        },
    },
};
