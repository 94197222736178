import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { TFilters } from './filters.types';
import {
    StyledFormControl,
    StyledImg,
    StyledIncidentCategory,
    StyledLabel,
} from './filters.styled';

export function Filters({ filter, setFilter, categoryOptions }: TFilters) {
    const { fromDate, toDate, category } = filter;

    const handleCategoryChange = event => {
        setFilter({ ...filter, category: event.target.value });
    };

    const handleDateFromChange = date => {
        if (date == 'Invalid Date') return;
        setFilter({ ...filter, fromDate: date });
    };

    const handleDateToChange = date => {
        if (date == 'Invalid Date') return;
        setFilter({ ...filter, toDate: date });
    };

    return (
        <div>
            <StyledFormControl as="fieldset">
                <FormLabel component="legend">Incident Category</FormLabel>
                <RadioGroup
                    aria-label="Incident Category"
                    name="Incident Category"
                    value={category}
                    onChange={handleCategoryChange}
                >
                    {categoryOptions.map((categoryOption, index) => (
                        <FormControlLabel
                            key={index}
                            value={categoryOption.value}
                            control={<Radio color="default" />}
                            label={
                                <StyledLabel>
                                    <StyledImg
                                        key={index}
                                        src={
                                            category === categoryOption.value
                                                ? categoryOption.imgActive
                                                : categoryOption.imgInactive
                                        }
                                        alt={categoryOption.label}
                                    />
                                    <StyledIncidentCategory component="span">
                                        {categoryOption.label}
                                    </StyledIncidentCategory>
                                </StyledLabel>
                            }
                        />
                    ))}
                </RadioGroup>
            </StyledFormControl>
            <StyledFormControl as="fieldset">
                <FormLabel component="legend">Dates</FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        id="From"
                        label="From"
                        format="dd/MM/yyyy"
                        value={fromDate}
                        onChange={handleDateFromChange}
                        maxDate={toDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change from date',
                        }}
                        autoOk={true}
                    />
                    <KeyboardDatePicker
                        margin="normal"
                        variant="inline"
                        id="To"
                        label="To"
                        format="dd/MM/yyyy"
                        value={toDate}
                        onChange={handleDateToChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change to date',
                        }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
            </StyledFormControl>
        </div>
    );
}
