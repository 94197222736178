import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';

import { Filters } from '@components';
import {
    StyledFunders,
    StyledImg,
    StyledDescriptionContainer,
    StyledFundersContainer,
    StyledSidebar,
} from './sidebar.styled';
import { TSidebar } from './sidebar.types';

export function Sidebar({
    filter,
    setFilter,
    categoryOptions,
    description,
    collaboratorLogos,
    funderLogos,
}: TSidebar) {
    return (
        <StyledSidebar>
            <Filters
                filter={filter}
                setFilter={setFilter}
                categoryOptions={categoryOptions}
            />
            <StyledDescriptionContainer>
                <Typography
                    color="textSecondary"
                    variant="body2"
                    component="div"
                >
                    {ReactHtmlParser(description)}
                </Typography>
            </StyledDescriptionContainer>
            {collaboratorLogos?.length > 0 && (
                <StyledFundersContainer>
                    <Typography variant="subtitle2">
                        In collaboration with
                    </Typography>
                    <StyledFunders>
                        {collaboratorLogos?.map((logo, index) => (
                            <StyledImg
                                key={index}
                                src={logo.logo}
                                alt={logo.description}
                            />
                        ))}
                    </StyledFunders>
                </StyledFundersContainer>
            )}
            {funderLogos.length > 0 && (
                <StyledFundersContainer>
                    <Typography variant="subtitle2">Funded by</Typography>
                    <StyledFunders>
                        {funderLogos?.map((logo, index) => (
                            <StyledImg
                                key={index}
                                src={logo.logo}
                                alt={logo.description}
                            />
                        ))}
                    </StyledFunders>
                </StyledFundersContainer>
            )}
        </StyledSidebar>
    );
}
