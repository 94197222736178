import { subWeeks } from 'date-fns';

import IMG_ALL from '../static/mozambique/all.png';
import IMG_AID from '../static/mozambique/aid.png';
import IMG_CRIME from '../static/mozambique/crime.png';
import IMG_EDUCATION from '../static/mozambique/education.png';
import IMG_FOOD_SECURITY from '../static/mozambique/food_security.png';
import IMG_HEALTH from '../static/mozambique/health.png';
import IMG_INDIVIDUALS from '../static/mozambique/individual.png';
import IMG_OPERATIONAL_SPACE from '../static/mozambique/operational_space.png';
import IMG_ROAD_SAFETY from '../static/mozambique/road_safety.png';
import IMG_HEALTH_FACILITY from '../static/mozambique/health_facility.png';
import IMG_EDUCATOR from '../static/mozambique/educator.png';

import mapactionLogo from '../static/logos/mapaction_logo.png';
import InsecurityInsightLogo from '../static/logos/insecurity_insight_logo_blue.png';

export const mozambique = {
    reportLabels: {
        perpetratorCategory: 'Reported perpetrator',
        weaponCategory: 'Weapon category used',
        categories: 'Categories',
        individualsDescription:
            'The number of health workers or educator injured is under reported and therefore not displayed.',
        aidWorkersInjured: 'Aid workers injured',
        aidWorkersKilledKidnapped: 'Aid workers killed or kidnapped',
        healthWorkersKilledKidnapped: 'Health workers killed or kidnapped',
        educatorsKilledKidnapped: 'Educators killed or kidnapped',
    },
    funders: [
        {
            logo: mapactionLogo,
            description: 'MapAction Logo',
            url: 'https://mapaction.org/',
            view: 'header',
        },
        {
            logo: InsecurityInsightLogo,
            description: 'Insecurity Insight Logo',
            url: 'http://insecurityinsight.org/',
            view: 'header',
        },
    ],
    layerNames: {
        adminBoundaries: 'adminBoundaries',
        incidentPoints: 'incidentPoints',
        incidentHeatmap: 'incidentHeatmap',
    },
    layerSourceNames: {
        adminBoundaries: 'adminBoundaries',
        incidentPoints: 'incidentPoints',
    },
    filters: {
        categorys: {
            all: 'All',
            aid: 'Aid',
            crime: 'Crime',
            education: 'Education',
            foodSecurityProgramme: 'FoodSecurityProgramme',
            healthProgramme: 'HealthProgramme',
            individuals: 'Individuals',
            nearMiss: 'NearMiss',
            operationalSpace: 'OperationalSpace',
            roadSafetyAccident: 'RoadSafetyAccident',
        },
        categoryOptions: [
            {
                value: 'Aid',
                label: 'Aid Affected',
                colour: '#000000',
                reportText: 'reported incidents affected aid.',
                imgActive: IMG_AID,
                imgInactive: IMG_AID,
            },
            {
                value: 'Crime',
                label: 'Crime',
                colour: '#000000',
                reportText: 'reported incidents were related to crime.',
                imgActive: IMG_CRIME,
                imgInactive: IMG_CRIME,
            },
            {
                value: 'Education',
                label: 'Education Affected',
                colour: '#000000',
                reportText:
                    'reported incidents affected education and education programmes.',
                imgActive: IMG_EDUCATION,
                imgInactive: IMG_EDUCATION,
            },
            {
                value: 'FoodSecurityProgramme',
                label: 'Food Security Programme Affected',
                colour: '#000000',
                reportText:
                    'reported events affected food security programmes.',
                imgActive: IMG_FOOD_SECURITY,
                imgInactive: IMG_FOOD_SECURITY,
            },
            {
                value: 'HealthProgramme',
                label: 'Health Programme Affected',
                colour: '#000000',
                reportText:
                    'reported incident affected health care or health programmes.',
                imgActive: IMG_HEALTH_FACILITY,
                imgInactive: IMG_HEALTH_FACILITY,
            },
            {
                value: 'Individuals',
                label: 'Individuals Affected',
                colour: '#000000',
                reportText:
                    'reported incidents critically affected aid or health workers or educators.',
                imgActive: IMG_INDIVIDUALS,
                imgInactive: IMG_INDIVIDUALS,
                aidWorkersInjured: IMG_AID,
                aidWorkersKilledKidnapped: IMG_AID,
                healthWorkersKilledKidnapped: IMG_HEALTH,
                educatorsKilledKidnapped: IMG_EDUCATOR,
            },
            {
                value: 'NearMiss',
                label: 'Near Miss',
                colour: '#000000',
                reportText: 'reported incidents described near misses.',
                imgActive: IMG_ALL, // Icon missing
                imgInactive: IMG_ALL,
            },
            {
                value: 'OperationalSpace',
                label: 'Operational Space Affected',
                colour: '#000000',
                reportText:
                    'reported incidents affected the operational space of aid agencies.',
                imgActive: IMG_OPERATIONAL_SPACE,
                imgInactive: IMG_OPERATIONAL_SPACE,
            },
            {
                value: 'RoadSafetyAccident',
                label: 'Road Safety Accident',
                colour: '#000000',
                reportText: 'reported road safety accidents.',
                imgActive: IMG_ROAD_SAFETY,
                imgInactive: IMG_ROAD_SAFETY,
            },
        ],
        dates: {
            from: new Date('2008-01-01'),
            to: subWeeks(new Date(), 6), // This is 6 weeks before today
        },
    },
};
