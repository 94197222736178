import React, {
    useRef,
    useEffect,
    useState,
    useReducer,
    Fragment,
    ReactElement,
} from 'react';

import mapboxgl, { Map as TMapboxMap } from '!mapbox-gl';
import CircularProgress from '@material-ui/core/CircularProgress';

import { TMozambiqueMap, TInitialState, TAction } from './mozambique-map.types';
import {
    initMap,
    updateIncidentLayers,
    aggregateIncidentData,
    getFilterColumn,
} from './mozambique-map.logic';
import { StyledMapProgress, StyledMapContainer } from './mozambique-map.styled';
import { filterMozambiqueIncidents } from '@helpers';

const INITIAL_STATE: TInitialState = {
    isMapLoading: true,
    filteredIncidents: [],
};

function reducer(state: TInitialState, action: TAction) {
    switch (action.type) {
        case 'updateMapLoadingState':
            return { ...state, isMapLoading: action.value };
        case 'setFilteredIncidents':
            return { ...state, filteredIncidents: action.value };
        default:
            return INITIAL_STATE;
    }
}

export function MozambiqueMap({
    filter,
    incidents,
    setReportData,
    setTotalIncidentsCount,
    dataSourceAttribution,
}: TMozambiqueMap): ReactElement {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const mapReference = useRef<HTMLDivElement>();
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    const [map, setMap] = useState<TMapboxMap>();
    const [isMapLoading, setIsMapLoading] = useState(true);

    useEffect(() => {
        const { filteredIncidents } = state;

        setReportData(aggregateIncidentData(filteredIncidents));
    }, [state]);

    useEffect(() => {
        if (!incidents || !map) {
            return;
        }

        const filteredIncidents = filterMozambiqueIncidents(
            incidents,
            filter,
            getFilterColumn(filter.category)
        );
        dispatch({ type: 'setFilteredIncidents', value: filteredIncidents });

        setTotalIncidentsCount(filteredIncidents.length);
        updateIncidentLayers(map, filteredIncidents);
    }, [incidents, filter]);

    useEffect(() => {
        if (mapReference.current && incidents) {
            const filteredIncidents = filterMozambiqueIncidents(
                incidents,
                filter,
                getFilterColumn(filter.category)
            );

            const mapboxMapObject = initMap(
                mapReference.current,
                filteredIncidents,
                dataSourceAttribution
            );

            setTotalIncidentsCount(filteredIncidents.length);
            setReportData(aggregateIncidentData(filteredIncidents));
            setIsMapLoading(false);
            setMap(mapboxMapObject);
        }
    }, [incidents]);

    return (
        <Fragment>
            {isMapLoading && (
                <StyledMapProgress>
                    <CircularProgress />
                </StyledMapProgress>
            )}
            <StyledMapContainer id="mozambique-map" ref={mapReference} />
        </Fragment>
    );
}
