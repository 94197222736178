export const DataConstants = {
    DATA_COLUMNS: {
        HEALTH: 'ALL_Events_affecting_healthcare',
        COVID: 'COVID_Related_Violence',
        CONFLICT: 'Conflict_Violence',
        LONGITUDE: 'Longitude',
        LATITUDE: 'Latitude',
        COUNTRY: 'Country',
        COUNTRY_ISO3: 'ISO_code',
        INCIDENT_NUMBER: 'Incident_Number',
        INCIDENT_DESCRIPTION: 'Edited_Incident_description',
        ISO_DATE: 'ISO-Date', // yyyy-mm-dd format
        HEALTH_FACILITIES_DAMAGED: 'Total_health_facilities_damaged',
        HEALTH_FACILITIES_DESTROYED: 'Total_health_facilities_destroyed',
        HEALTH_WORKERS_KILLED: 'Total_health_worker_killed',
        HEALTH_WORKERS_KIDNAPPED: 'Total_health_worker_kidnapped',
        HEALTH_WORKERS_INJURED: 'Total_health_worker_injured',
        HEALTH_WORKERS_ASSAULTED: 'Total_health_worker_assaulted',
        DISPLAY_DESCRIPTION_STATUS: 'Display_Status',
        PERPETRATOR_TYPE: 'Perpetrator_MAP',
        WEAPON_USE_TYPE: 'Weapon_use_MAP',
        HEALTH_MEASURE_TYPE: 'Health_measure_MAP',
        POLITICAL: 'Political_related_violence',
        EBOLA: 'Ebola_related_violence',
        VACCINATION: 'Vaccination_related_violence',
    },
};
