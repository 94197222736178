import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { format } from 'date-fns';

import { TableReport } from '@components';
import {
    covid,
    REPORT_LABELS,
    PERPETRATOR_TYPE_LOOKUP,
    WEAPON_USE_TYPE_LOOKUP,
    HEALTH_MEASURE_TYPE_LOOKUP,
} from '@constants';
import { getAlphabeticallySortedData } from '@helpers';
import { TReport } from './report.types';
import {
    StyledIncidents,
    StyledHide,
    StyledCategory,
    StyledEmphasis,
    StyledTable,
    StyledReport,
} from './report.styled';

export function Report({
    selectedCountry,
    filter,
    filterCategory,
    clearSelectedCountry,
}: TReport) {
    const {
        country,
        totalIncidents,
        healthFacilitiesDamagedDestroyed,
        healthWorkersKilled,
        healthWorkersKidnapped,
        healthWorkersInjured,
        perpetrators,
        weaponUse,
        healthMeasureType,
    } = selectedCountry;

    return (
        <StyledReport>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <img
                        src={filterCategory?.imgActive}
                        width="60px"
                        height="auto"
                        style={{ display: 'block' }}
                        alt={'Logo'}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h6">{country}</Typography>
                    <Typography variant="subtitle2">
                        {format(new Date(filter.fromDate), 'dd/MM/yyyy')} -{' '}
                        {format(new Date(filter.toDate), 'dd/MM/yyyy')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <StyledHide onClick={clearSelectedCountry}>X</StyledHide>
                </Grid>
            </Grid>

            <StyledIncidents>
                <Typography>
                    <StyledEmphasis>{totalIncidents}</StyledEmphasis>{' '}
                    {filterCategory.reportText}
                </Typography>
            </StyledIncidents>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={3}>
                    <StyledCategory>
                        <img
                            src={filterCategory.facilities}
                            width="60px"
                            height="auto"
                            alt={
                                REPORT_LABELS.HEALTH_FACILITIES_DAMAGED_DESTROYED
                            }
                        />
                        <Typography variant="h5">
                            {healthFacilitiesDamagedDestroyed}
                        </Typography>
                        <Typography variant="caption" display="block">
                            {REPORT_LABELS.HEALTH_FACILITIES_DAMAGED_DESTROYED}
                        </Typography>
                    </StyledCategory>
                </Grid>
                <Grid item xs={3}>
                    <StyledCategory>
                        <img
                            src={filterCategory.killed}
                            width="60px"
                            height="auto"
                            style={{ display: 'block' }}
                            alt={REPORT_LABELS.HEALTH_WORKERS_KILLED}
                        />
                        <Typography variant="h5">
                            {healthWorkersKilled}
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {REPORT_LABELS.HEALTH_WORKERS_KILLED}
                        </Typography>
                    </StyledCategory>
                </Grid>
                <Grid item xs={3}>
                    <StyledCategory>
                        <img
                            src={filterCategory.kidnapped}
                            width="60px"
                            height="auto"
                            style={{ display: 'block' }}
                            alt={REPORT_LABELS.HEALTH_WORKERS_KIDNAPPED}
                        />
                        <Typography variant="h5">
                            {healthWorkersKidnapped}
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {REPORT_LABELS.HEALTH_WORKERS_KIDNAPPED}
                        </Typography>
                    </StyledCategory>
                </Grid>
                <Grid item xs={3}>
                    <StyledCategory>
                        <img
                            src={filterCategory.injured}
                            width="60px"
                            height="auto"
                            style={{ display: 'block' }}
                            alt={REPORT_LABELS.HEALTH_WORKERS_INJURED}
                        />
                        <Typography variant="h5">
                            {healthWorkersInjured}
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            {REPORT_LABELS.HEALTH_WORKERS_INJURED}
                        </Typography>
                    </StyledCategory>
                </Grid>
            </Grid>
            <StyledTable>
                {perpetrators && (
                    <TableReport
                        header={REPORT_LABELS.PERPETRATOR_CATEGORY}
                        data={getAlphabeticallySortedData(perpetrators)}
                        valueLookup={PERPETRATOR_TYPE_LOOKUP}
                        colour={filterCategory.colour}
                    />
                )}
            </StyledTable>
            <StyledTable>
                {weaponUse && (
                    <TableReport
                        header={REPORT_LABELS.WEAPON_CATEGORY}
                        data={getAlphabeticallySortedData(weaponUse)}
                        valueLookup={WEAPON_USE_TYPE_LOOKUP}
                        colour={filterCategory.colour}
                    />
                )}
            </StyledTable>
            {filter.category === covid.filters.categorys.covid && (
                <StyledTable>
                    {healthMeasureType && (
                        <TableReport
                            header={REPORT_LABELS.HEALTH_MEASURE_CATEGORY}
                            data={getAlphabeticallySortedData(
                                healthMeasureType
                            )}
                            valueLookup={HEALTH_MEASURE_TYPE_LOOKUP}
                            colour={filterCategory.colour}
                        />
                    )}
                </StyledTable>
            )}
        </StyledReport>
    );
}
