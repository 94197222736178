import { TGlobalStatistics } from '@components';
import { aggregateHealthImpactCaused } from '@helpers';

export function calculateGlobalTotals<T>(
    filteredIncidents: T[]
): TGlobalStatistics {
    const aggregateHealthImpacts = aggregateHealthImpactCaused(
        filteredIncidents
    );
    return {
        totalIncidents: filteredIncidents.length,
        healthFacilitiesDamagedDestroyed:
            aggregateHealthImpacts('totalHealthFacilitiesDamaged') +
            aggregateHealthImpacts('totalHealthFacilitiesDestroyed'),
        healthWorkersKilled: aggregateHealthImpacts('totalHealthWorkerKilled'),
        healthWorkersKidnapped: aggregateHealthImpacts(
            'totalHealthWorkerKidnapped'
        ),
        healthWorkersInjured:
            aggregateHealthImpacts('totalHealthWorkerInjured') +
            aggregateHealthImpacts('totalHealthWorkerAassaulted'),
    };
}
