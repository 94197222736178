type TAggregateIncidentsByPerpetrators = {
    [key: string]: number;
};

export function aggregateIncidentsByPerpetrators(column: string) {
    let perpetrators = {};
    return incidents =>
        incidents.reduce((accumulator, currentValue) => {
            const perpetrator =
                currentValue[column] === ''
                    ? 'No information'
                    : currentValue[column];

            if (Object.keys(perpetrators).includes(perpetrator)) {
                return {
                    ...accumulator,
                    [perpetrator]: accumulator[perpetrator] + 1,
                };
            }

            perpetrators = {
                ...perpetrators,
                [perpetrator]: perpetrator,
            };

            return {
                ...accumulator,
                [perpetrator]: 1,
            };
        }, {} as TAggregateIncidentsByPerpetrators);
}

export function aggregateHealthImpactCaused<T>(incidents: T[]) {
    return (column: string): number =>
        incidents.reduce((accumulator, currentValue) => {
            const value = Number(currentValue[column]);
            return accumulator + (Number.isNaN(value) ? 0 : value);
        }, 0);
}
