import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';

import { IncidentEffectsReport } from '@components';
import { TTotalIncidentCount } from './total-incident-count.types';
import {
    StyledIncidentCount,
    StyledIncidentRow,
    StyledIncidentEmphasis,
    StyledIcon,
    StyledTypography,
} from './total-incidents-count.styled';

export function TotalIncidentsCount({
    filter,
    filterCategory,
    globalStatistics,
    showGlobalStatistics = false,
}: TTotalIncidentCount): ReactElement {
    return (
        <StyledIncidentCount>
            <StyledIncidentRow>
                <StyledIcon src={filterCategory?.imgActive} alt={'Logo'} />
                <StyledTypography variant="subtitle2">
                    {filterCategory?.labelGlobalStats} related incidents
                    reported globally:
                    <StyledIncidentEmphasis>
                        {globalStatistics?.totalIncidents}
                    </StyledIncidentEmphasis>
                </StyledTypography>
            </StyledIncidentRow>
            <StyledIncidentRow>
                <Typography variant="subtitle2" align="center">
                    {format(new Date(filter.fromDate), 'dd/MM/yyyy')} –{' '}
                    {format(new Date(filter.toDate), 'dd/MM/yyyy')}
                </Typography>
            </StyledIncidentRow>
            {showGlobalStatistics && (
                <StyledIncidentRow>
                    <IncidentEffectsReport {...globalStatistics} />
                </StyledIncidentRow>
            )}
        </StyledIncidentCount>
    );
}
