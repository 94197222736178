import mapboxgl, { Map } from '!mapbox-gl';

import { TMozambiqueSecurityIncident } from '@types';
import {
    MAPBOX_BASEMAP_STYLE,
    mozambique,
    INCIDENTS_ICON,
    NO_DESCRIPTION_AVAILABLE,
} from '@constants';
import { createIcon } from '@helpers';
import { TIncidentsGeoJson } from './covid-map.types';

import adminBoundaries from '../../data/mozambique_admin1.json';
import { TMozambiqueReportData } from '@components';

const { layerSourceNames, layerNames } = mozambique;

function addIncidentsPopupEvents(map: Map) {
    return event => {
        const feature = event.features[0];
        const coordinates = feature.geometry.coordinates.slice();
        const description = feature.properties.incidentDescription
            ? `${feature.properties.incidentDescription} (ID: ${feature.properties.incidentNumber})`
            : `${NO_DESCRIPTION_AVAILABLE} (ID: ${feature.properties.incidentNumber})`;

        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(description)
            .addTo(map);
    };
}

export function aggregateIncidentData(
    incidents: TMozambiqueSecurityIncident[]
): TMozambiqueReportData {
    function aggregateImpactCaused(column: string): number {
        return incidents.reduce((accumulator, currentValue) => {
            const value = Number(currentValue[column]);
            return accumulator + (Number.isNaN(value) ? 0 : value);
        }, 0);
    }

    function aggregateIncident(column: string) {
        let perpetrators = {};
        return () =>
            incidents.reduce((accumulator, currentValue) => {
                const perpetrator =
                    currentValue[column] === ''
                        ? 'No information'
                        : currentValue[column];

                if (Object.keys(perpetrators).includes(perpetrator)) {
                    return {
                        ...accumulator,
                        [perpetrator]: accumulator[perpetrator] + 1,
                    };
                }

                perpetrators = {
                    ...perpetrators,
                    [perpetrator]: perpetrator,
                };

                return {
                    ...accumulator,
                    [perpetrator]: 1,
                };
            }, {});
    }

    const reportData = {
        totalIncidents: incidents.length,
        perpetrators: aggregateIncident('perpetratorMap')(),
        weaponUse: aggregateIncident('weaponUseMap')(),
        crime: aggregateIncident('crime')(),
        education: aggregateIncident('education')(),
        foodSecurity: aggregateIncident('foodSecurity')(),
        health: aggregateIncident('health')(),
        nearMiss: aggregateIncident('nearMiss')(),
        operationalSpace: aggregateIncident('operationalSpace')(),
        roadSafetyAccident: aggregateIncident('roadSafetyAccident')(),
        aidWorkersInjured: aggregateImpactCaused('totalAidWorkerInjured'),
        aidWorkersKilledKidnapped:
            aggregateImpactCaused('totalAidWorkerKidnapped') +
            aggregateImpactCaused('totalAidWorkerKilled'),
        healthWorkersKilledKidnapped:
            aggregateImpactCaused('totalHealthWorkerKidnapped') +
            aggregateImpactCaused('totalHealthWorkerKilled'),
        educatorsKilledKidnapped:
            aggregateImpactCaused('totalEducatorKidnapped') +
            aggregateImpactCaused('totalEducatorKilled'),
    };

    return reportData;
}

export function initMap(
    mapContainer: HTMLDivElement,
    incidents: TMozambiqueSecurityIncident[],
    dataSourceAttribution
) {
    const map = new mapboxgl.Map({
        container: mapContainer,
        style: MAPBOX_BASEMAP_STYLE,
        attributionControl: false,
        maxBounds: [
            [-180, -85],
            [180, 85],
        ],
    });

    map.on('load', () => {
        addAdminBoundariesLayerToMap(map);
        addIncidentsLayersToMap(incidents, map);
    });

    map.addControl(
        new mapboxgl.NavigationControl({
            showCompass: false,
        })
    );

    // Custom attribution
    map.addControl(
        new mapboxgl.AttributionControl({
            customAttribution: dataSourceAttribution,
        })
    );

    map.fitBounds(
        [
            [30.1794812355, -26.7421916643],
            [40.7754752948, -10.3170960425],
        ],
        { padding: 10 }
    );

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', layerSourceNames.incidentPoints, function () {
        map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a grab when it leaves.
    map.on('mouseleave', layerSourceNames.incidentPoints, function () {
        map.getCanvas().style.cursor = 'grab';
    });

    map.on(
        'click',
        layerSourceNames.incidentPoints,
        addIncidentsPopupEvents(map)
    );

    return map;
}

function addAdminBoundariesLayerToMap(map: Map) {
    map.addSource(layerSourceNames.adminBoundaries, {
        type: 'geojson',
        data: adminBoundaries,
    });

    map.addLayer({
        id: layerNames.adminBoundaries,
        source: layerSourceNames.adminBoundaries,
        type: 'fill',
        paint: {
            'fill-opacity': 0.3,
            'fill-outline-color': 'gray',
        },
    });
}

export function addIncidentsLayersToMap(
    incidents: TMozambiqueSecurityIncident[],
    map: Map
): void {
    map.addImage(
        'incidentsIcon',
        createIcon(INCIDENTS_ICON.COLOUR, INCIDENTS_ICON.BORDER_COLOUR),
        { pixelRatio: 2 }
    );

    map.addSource(layerSourceNames.incidentPoints, {
        type: 'geojson',
        data: createIncidentsGeoJson(incidents),
    });
    map.addLayer({
        id: layerNames.incidentPoints,
        source: layerSourceNames.incidentPoints,
        type: 'symbol',
        minzoom: 8,
        layout: {
            'icon-image': 'incidentsIcon',
            'icon-allow-overlap': true,
        },
    });

    // Heatmap Layer
    map.addLayer({
        id: layerNames.incidentHeatmap,
        type: 'heatmap',
        source: layerSourceNames.incidentPoints,
        maxzoom: 9,
        paint: {
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                9,
                3,
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.2,
                'rgb(103,169,207)',
                0.4,
                'rgb(209,229,240)',
                0.6,
                'rgb(253,219,199)',
                0.8,
                'rgb(239,138,98)',
                1,
                'rgb(178,24,43)',
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                8,
                36,
                80,
            ],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                7,
                1,
                9,
                0,
            ],
        },
    });
}

export function updateIncidentLayers(
    map: Map,
    incidents: TMozambiqueSecurityIncident[]
) {
    map.getSource(layerSourceNames.incidentPoints).setData(
        createIncidentsGeoJson(incidents)
    );
}

function getFilterColumn(category: string): string {
    switch (category) {
        case 'Crime':
            return 'crime';
        case 'Education':
            return 'education';
        case 'FoodSecurityProgramme':
            return 'foodSecurity';
        case 'HealthProgramme':
            return 'health';
        case 'Individuals':
            return 'individualsAffected';
        case 'NearMiss':
            return 'nearMiss';
        case 'OperationalSpace':
            return 'operationalSpace';
        case 'RoadSafetyAccident':
            return 'roadSafetyAccident';
        default:
            return '';
    }
}

function createIncidentsGeoJson(
    incidents: TMozambiqueSecurityIncident[]
): TIncidentsGeoJson {
    return {
        type: 'FeatureCollection',
        features: incidents
            .map(incident => {
                if (incident.latitude && incident.longitude) {
                    return {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [
                                Number(incident.longitude.replace(/ /g, '')),
                                Number(incident.latitude.replace(/ /g, '')),
                            ],
                        },
                        properties: {
                            incidentNumber: incident.incidentNumber,
                            incidentDescription:
                                incident.editedIncidentDescription,
                            isoDate: incident.isoDate,
                        },
                    };
                }
            })
            .filter(Boolean),
    };
}

export { getFilterColumn };
