import edaLogo from '../static/logos/eda_logo.jpg';
import h2hLogo from '../static/logos/h2h_full_logo.png';
import ukAidLogo from '../static/logos/uk_aid_logo.png';
import gffoLogo from '../static/logos/gffo_logo.png';
import riahLogo from '../static/logos/riah_logo.png';
import mapactionLogo from '../static/logos/mapaction_logo.png';
import InsecurityInsightLogo from '../static/logos/insecurity_insight_logo_blue.png';
import shicLogo from '../static/logos/safeguarding_health_in_conflict.png';
import jhLogo from '../static/logos/JHSPHLogo.jpg';
import dornsifeLogo from '../static/logos/Dornsife.jpg';
import icnLogo from '../static/logos/ICNlogo.png';
import phrLogo from '../static/logos/phr_logo.png';
import intrahealthLogo from '../static/logos/Intrahealth.png';
// import { subWeeks } from 'date-fns';

import IMG_HEALTH_ACTIVE from '../static/covid/health_active.png';
import IMG_HEALTH_INACTIVE from '../static/covid/health_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_ALL from '../static/covid/health_facilities_damaged_destroyed_all.png';
import IMG_HEALTH_WORKERS_INJURED_ALL from '../static/covid/health_workers_injured_all.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_ALL from '../static/covid/health_workers_kidnapped_all.png';
import IMG_HEALTH_WORKERS_KILLED_ALL from '../static/covid/health_workers_killed_all.png';

import IMG_COVID_ACTIVE from '../static/covid/covid_active.png';
import IMG_COVID_INACTIVE from '../static/covid/covid_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_COVID from '../static/covid/health_facilities_damaged_destroyed_covid.png';
import IMG_HEALTH_WORKERS_INJURED_COVID from '../static/covid/health_workers_injured_covid.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_COVID from '../static/covid/health_workers_kidnapped_covid.png';
import IMG_HEALTH_WORKERS_KILLED_COVID from '../static/covid/health_workers_killed_covid.png';

import IMG_CONFLICT_ACTIVE from '../static/covid/conflict_active.png';
import IMG_CONFLICT_INACTIVE from '../static/covid/conflict_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_CONFLICT from '../static/covid/health_facilities_damaged_destroyed_conflict.png';
import IMG_HEALTH_WORKERS_INJURED_CONFLICT from '../static/covid/health_workers_injured_conflict.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_CONFLICT from '../static/covid/health_workers_kidnapped_conflict.png';
import IMG_HEALTH_WORKERS_KILLED_CONFLICT from '../static/covid/health_workers_killed_conflict.png';

import IMG_POLITICAL_ACTIVE from '../static/covid/political_active.png';
import IMG_POLITICAL_INACTIVE from '../static/covid/political_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_POLITICAL from '../static/covid/health_facilities_damaged_destroyed_political.png';
import IMG_HEALTH_WORKERS_INJURED_POLITICAL from '../static/covid/health_workers_injured_political.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_POLITICAL from '../static/covid/health_workers_kidnapped_political.png';
import IMG_HEALTH_WORKERS_KILLED_POLITICAL from '../static/covid/health_workers_killed_political.png';

import IMG_VACCINATION_ACTIVE from '../static/covid/vaccination_active.png';
import IMG_VACCINATION_INACTIVE from '../static/covid/vaccination_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_VACCINATION from '../static/covid/health_facilities_damaged_destroyed_vaccination.png';
import IMG_HEALTH_WORKERS_INJURED_VACCINATION from '../static/covid/health_workers_injured_vaccination.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_VACCINATION from '../static/covid/health_workers_kidnapped_vaccination.png';
import IMG_HEALTH_WORKERS_KILLED_VACCINATION from '../static/covid/health_workers_killed_vaccination.png';

import IMG_EBOLA_ACTIVE from '../static/covid/ebola_active.png';
import IMG_EBOLA_INACTIVE from '../static/covid/ebola_inactive.png';
import IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_EBOLA from '../static/covid/health_facilities_damaged_destroyed_ebola.png';
import IMG_HEALTH_WORKERS_INJURED_EBOLA from '../static/covid/health_workers_injured_ebola.png';
import IMG_HEALTH_WORKERS_KIDNAPPED_EBOLA from '../static/covid/health_workers_kidnapped_ebola.png';
import IMG_HEALTH_WORKERS_KILLED_EBOLA from '../static/covid/health_workers_killed_ebola.png';

export const health = {
    funders: [
        {
            logo: edaLogo,
            description: 'EDA Logo',
            view: 'sidebar',
        },
        {
            logo: riahLogo,
            description: 'Riah Logo',
            view: 'sidebar',
        },
        {
            logo: ukAidLogo,
            description: 'UK Aid Logo',
            view: 'sidebar',
        },
        {
            logo: gffoLogo,
            description: 'GFFO Logo',
            view: 'sidebar',
        },
        {
            logo: h2hLogo,
            description: 'H2H Network Logo',
            view: 'sidebar',
        },
        {
            logo: InsecurityInsightLogo,
            description: 'Insecurity Insight Logo',
            url: 'http://insecurityinsight.org/',
            view: 'header',
        },
        {
            logo: shicLogo,
            description: 'Safeguarding Health in Conflict Logo',
            url: 'https://www.safeguardinghealth.org/',
            view: 'header',
        },
        {
            logo: mapactionLogo,
            description: 'MapAction Logo',
            url: 'https://mapaction.org/',
            view: 'header',
        },
        {
            logo: jhLogo,
            description: 'Johns Hopkins Logo',
            url: 'https://www.jhsph.edu/',
            view: 'sidebar-collaborator',
        },
        {
            logo: dornsifeLogo,
            description: 'Dornsife Logo',
            url: 'https://drexel.edu/dornsife/',
            view: 'sidebar-collaborator',
        },
        {
            logo: icnLogo,
            description: 'ICN Logo',
            url: 'https://www.icn.ch/',
            view: 'sidebar-collaborator',
        },
        {
            logo: phrLogo,
            description: 'Physicians for Human Rights Logo',
            url: 'https://phr.org/',
            view: 'sidebar-collaborator',
        },
        {
            logo: intrahealthLogo,
            description: 'Intrahealth Logo',
            url: 'https://www.intrahealth.org/',
            view: 'sidebar-collaborator',
        },
    ],
    filters: {
        categorys: {
            health: 'Health',
            covid: 'Covid',
            conflict: 'Conflict',
            policical: 'Political',
            ebola: 'Ebola',
            vaccination: 'Vaccination',
        },
        categoryOptions: [
            {
                value: 'Health',
                label: 'All incidents',
                labelGlobalStats: 'Health care',
                colour: '#4281C2',
                reportText:
                    'reported incidents of violence or threat of violence against health care. These incidents had the following effects:',
                imgActive: IMG_HEALTH_ACTIVE,
                imgInactive: IMG_HEALTH_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_ALL,
                injured: IMG_HEALTH_WORKERS_INJURED_ALL,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_ALL,
                killed: IMG_HEALTH_WORKERS_KILLED_ALL,
            },
            {
                value: 'Conflict',
                label: 'Conflict',
                labelGlobalStats: 'Conflict',
                colour: '#920000',
                reportText:
                    'reported incidents of conflict violence that affected health care. These incidents had the following effects:',
                imgActive: IMG_CONFLICT_ACTIVE,
                imgInactive: IMG_CONFLICT_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_CONFLICT,
                injured: IMG_HEALTH_WORKERS_INJURED_CONFLICT,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_CONFLICT,
                killed: IMG_HEALTH_WORKERS_KILLED_CONFLICT,
            },
            {
                value: 'Covid',
                label: 'COVID',
                labelGlobalStats: 'COVID-19',
                colour: '#548235',
                reportText:
                    'reported incidents of violence or threats of violence against health care directly related to COVID-19. These incidents had the following effects:',
                imgActive: IMG_COVID_ACTIVE,
                imgInactive: IMG_COVID_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_COVID,
                injured: IMG_HEALTH_WORKERS_INJURED_COVID,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_COVID,
                killed: IMG_HEALTH_WORKERS_KILLED_COVID,
            },
            {
                value: 'Ebola',
                label: 'Ebola',
                labelGlobalStats: 'Ebola',
                colour: '#007770',
                reportText:
                    'reported incidents of violence or threat of violence against health care directly related to Ebola. These incidents had the following effects:',
                imgActive: IMG_EBOLA_ACTIVE,
                imgInactive: IMG_EBOLA_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_EBOLA,
                injured: IMG_HEALTH_WORKERS_INJURED_EBOLA,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_EBOLA,
                killed: IMG_HEALTH_WORKERS_KILLED_EBOLA,
            },
            {
                value: 'Political',
                label: 'Political',
                labelGlobalStats: 'Political volatility',
                colour: '#fd8d3c',
                reportText:
                    'reported incidents of violence or threat of violence against health care in the context of political volatility. These incidents had the following effects:',
                imgActive: IMG_POLITICAL_ACTIVE,
                imgInactive: IMG_POLITICAL_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_POLITICAL,
                injured: IMG_HEALTH_WORKERS_INJURED_POLITICAL,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_POLITICAL,
                killed: IMG_HEALTH_WORKERS_KILLED_POLITICAL,
            },
            {
                value: 'Vaccination',
                label: 'Vaccination',
                labelGlobalStats: 'Vaccination',
                colour: '#f9bb34',
                reportText:
                    'reported incidents of violence or threat of violence against vaccination rollouts. These incidents had the following effects:',
                imgActive: IMG_VACCINATION_ACTIVE,
                imgInactive: IMG_VACCINATION_INACTIVE,
                facilities: IMG_HEALTH_FACILITIES_DAMAGED_DESTROYED_VACCINATION,
                injured: IMG_HEALTH_WORKERS_INJURED_VACCINATION,
                kidnapped: IMG_HEALTH_WORKERS_KIDNAPPED_VACCINATION,
                killed: IMG_HEALTH_WORKERS_KILLED_VACCINATION,
            },
        ],
        dates: {
            from: new Date('2016-01-01'),
            // to: subWeeks(new Date(), 3), // This is 3 weeks before today
            to: new Date(), // This is today
        },
    },
};
