export function getAlphabeticallySortedData(
    unordered
): { [key: string]: number } {
    const ordered = {};
    // Sort alphabetically but put the empty records which are shown as 'No information' last
    for (const key of Object.keys(unordered).sort((a, b) => {
        if (!a || a === 'No information') {
            return 1;
        }
        if (!b || b === 'No information') {
            return -1;
        }

        return a.localeCompare(b);
    })) {
        ordered[key] = unordered[key];
    }
    return ordered;
}
