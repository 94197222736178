import React, { PropsWithChildren, ReactElement } from 'react';

import { StyledMainContainer } from './main-wrapper.styled';

export function MainWrapper({
    children,
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
    return (
        <StyledMainContainer container spacing={0}>
            {children}
        </StyledMainContainer>
    );
}
