import React, { ReactElement } from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import { BarChart } from '@components';
import { NO_DATA } from '@constants';
import { StyledTableCell } from './table-report.styled';

export function TableReport({
    header,
    data,
    valueLookup,
    colour,
}): ReactElement {
    const getMaxValue = data => {
        // Filter out any values where the key is an empty string (and is not assigned a category)
        const filtered = Object.keys(data).reduce((object, key) => {
            object[key] = data[key];
            return object;
        }, {});

        const dataValues = Object.values(filtered);
        return Math.max(...dataValues);
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell colSpan={3} paddingLeft={false}>
                            <strong>{header}</strong>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(data).map((key, index) => (
                        <TableRow key={index}>
                            <StyledTableCell
                                component="th"
                                scope="row"
                                paddingLeft={false}
                            >
                                {valueLookup ? valueLookup[key] : key}
                            </StyledTableCell>
                            <StyledTableCell width="100" paddingLeft>
                                <BarChart
                                    value={data[key]}
                                    maxValue={getMaxValue(data)}
                                    colour={colour}
                                ></BarChart>
                            </StyledTableCell>
                            <StyledTableCell align="right" paddingLeft>
                                {data[key]}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {Object.keys(data).length === 0 && (
                <StyledTableCell paddingLeft={false}>{NO_DATA}</StyledTableCell>
            )}
        </TableContainer>
    );
}
